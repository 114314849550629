/* Portfolio container with perspective and overflow hidden */
.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    perspective: 1500px; /* Add 3D perspective */
    overflow: hidden;
  }
  
  /* Portfolio item styling */
  .portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    transform-style: preserve-3d; /* Enable 3D transformations */
    position: relative;
    animation: shuffle 2s ease-out forwards;
    /* Set initial state of the card for both axes flip */
    transform: rotateY(0deg) rotateX(0deg);
  }
  
  /* Animation for portfolio items */
  @keyframes shuffle {
    0% {
      transform: rotateY(-90deg) scale(0);
      opacity: 0;
    }
    50% {
      transform: rotateY(10deg) scale(1.1);
      opacity: 0.5;
    }
    100% {
      transform: rotateY(0deg) scale(1);
      opacity: 1;
    }
  }
  
  /* Hover effect for portfolio item */
  .portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
    /* Flip the item both vertically and horizontally */
    transform: rotateY(180deg) rotateX(180deg);
  }
  
  /* Parallax effect on the images */
  .portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 30%;
    transition: transform 0.3s;
    transform: translateZ(0); /* Initiate parallax effect */
  }
  
  .portfolio__item-image img {
    transition: transform 0.3s ease;
  }
  
  /* On hover, slightly zoom image */
  .portfolio__item:hover .portfolio__item-image {
    transform: scale(1.1);
  }
  
  /* Parallax scroll effect - move background at a different speed */
  @media screen and (max-width: 1024px) {
    .portfolio__container {
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .portfolio__container {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
  
  /* Parallax effect on scroll */
  .portfolio__container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateY(0) scale(1.1);
    will-change: transform;
    animation: parallax-scroll 5s infinite linear;
  }
  
  @keyframes parallax-scroll {
    0% {
      transform: translateY(0) scale(1.1);
    }
    100% {
      transform: translateY(-50%) scale(1.15);
    }
  }
  