/* res.css */

/* Navbar container styles */
.navbar1 {
    background-color: transparent; /* Dark gray */
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    padding: 16px 0;
  }
  
  .container1 {
    display: flex;
    justify-content: space-between; /* Align logo to the left and menu to the right */
    align-items: center;
    padding: 0 20px;
  }
  
  .logo-container {
    /* Left side container for logo */
    flex-basis: 20%;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-decoration: none;
  }
  
  .logo:hover {
    color: #e2e8f0;
  }
  

  
  .desktop-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2c2c6c; /* Dark background */
    padding: 1rem;
    border-radius: 40px;
}

.menu-item {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
    font-size: 1.2rem;
    transition: transform 0.3s ease, color 0.3s ease;
    position: relative;
    display: inline-block;
    cursor: pointer;
    transform-origin: bottom; /* Animate scale from bottom */
}

.menu-item:hover {
    color: #74adf7; /* Lighter gray */
    animation: dockJump 0.4s ease forwards, dockPulse 0.4s ease-in-out infinite alternate;
}

/* Rectangle box animation on hover */
.menu-item::before, .menu-item::after {
    content: "";
    position: absolute;
    border: 2px solid #74adf7;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.menu-item::before {
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-right: none;
    border-bottom: none;
    transition: width 0.3s ease, opacity 0s ease 0.3s;
}

.menu-item::after {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0;
    border-left: none;
    border-top: none;
    transition: height 0.3s ease 0.3s;
}

.menu-item:hover::before {
    width: 100%;
    opacity: 1;
}

.menu-item:hover::after {
    height: 100%;
    opacity: 1;
}

@keyframes dockJump {
    0% {
        transform: scale(1) translateY(0);
    }
    30% {
        transform: scale(1.2) translateY(-8px); /* Initial jump */
    }
    60% {
        transform: scale(1.15) translateY(-4px); /* Settle down slightly */
    }
    100% {
        transform: scale(1) translateY(0); /* Return to normal */
    }
}

@keyframes dockPulse {
    0% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1.1);
    }
}

  
  
  
  
  .mobile-menu-button {
    display: none;
    background: none;
    border: none;
    color: #e2e8f0;
    cursor: pointer;
  }
  
  .menu-icon {
    width: 24px;
    height: 24px;
  }
  
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #4a5568; /* Darker gray */
    padding: 20px;
    display: flex;
    flex-direction: column;
    z-index: 20;
  }
  
  .close-button {
    align-self: flex-end;
    color: #e2e8f0;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  
  /* Media Queries for responsiveness */
  @media (min-width: 768px) {
    .desktop-menu {
      display: flex;
    }
    
    .mobile-menu-button {
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    .desktop-menu {
      display: none;
    }
  
    .mobile-menu-button {
      display: inline-block;
    }
  }
  